// import * as regexp from 'lib/regexp';
import isBrowser from 'lib/isBrowser';

// export const getEnvValue = <T extends string>(env: T | undefined): T | undefined => env?.replaceAll('\'', '"') as T;

export const replaceQuotes = (value: string | undefined) => value?.replaceAll('\'', '"');

export const getEnvValue = (envName: string) => {
  // eslint-disable-next-line no-restricted-properties
  const envs = isBrowser() ? window.__envs : process.env;
  // console.log(`check check check: ${envName}`, isBrowser());
  

  if (isBrowser() && envs.NEXT_PUBLIC_APP_INSTANCE === 'pw') {
    const storageValue = localStorage.getItem(envName);

    if (typeof storageValue === 'string') {
      return storageValue;
    }
  }

  return replaceQuotes(envs[envName]);
};

export const parseEnvJson = <DataType>(env: string | undefined): DataType | null => {
  try {
    return JSON.parse(env || 'null') as DataType | null;
  } catch (error) {
    return null;
  }
};

// export const getExternalAssetFilePath = (envName: string, envValue: string | undefined) => {
export const getExternalAssetFilePath = (envName: string) => {
  const parsedValue = getEnvValue(envName);
  if (envName === "NEXT_PUBLIC_TOKEN_SOCIAL_LINK_URL") {
    
    console.log("getExternalAssetFilePath envName: ****", envName, parsedValue);
  }

  if (!parsedValue) {
    return;
  }
  // console.log("getExternalAssetFilePath: ****", parsedValue);

  // const fileName = envName.replace(/^NEXT_PUBLIC_/, '').replace(/_URL$/, '').toLowerCase();
  // const fileExtension = parsedValue.match(regexp.FILE_EXTENSION)?.[1];

  // return `/assets/${ fileName }.${ fileExtension }`;
  return parsedValue;
};
