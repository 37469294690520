import type { Feature } from './types';

import { getEnvValue } from '../utils';

const apiEndpoint = getEnvValue('NEXT_PUBLIC_SECURITY_PROFILE_API_URL');
const authToken = getEnvValue('NEXT_PUBLIC_SECURITY_PROFILE_API_TOKEN');

const title = 'Security profile from solidityscan';

const config: Feature<{
  api: { endpoint: string; basePath: string; authToken: string };
}> = (() => {
  if (apiEndpoint) {
    return Object.freeze({
      title,
      isEnabled: true,
      api: {
        endpoint: apiEndpoint,
        basePath: '',
        authToken: authToken ? `Token ${ authToken }` : '',
      },
    });
  }

  return Object.freeze({
    title,
    isEnabled: false,
  });
})();

export default config;
