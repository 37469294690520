import { getEnvValue } from './utils';

export default Object.freeze({
  reCaptcha: {
    siteKey: getEnvValue('NEXT_PUBLIC_RE_CAPTCHA_APP_SITE_KEY'),
  },
  ibzBot: {
    websiteToken: getEnvValue('NEXT_PUBLIC_IBZBOT_WEBSITE_TOKEN'),
    baseURL: getEnvValue('NEXT_PUBLIC_IBZBOT_BASE_URL'),
    iconURL: getEnvValue('NEXT_PUBLIC_IBZBOT_ICON_URL'),
  },
});
